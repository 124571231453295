<!-- 公司荣誉 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text5 }}</h3>
      </div>
    </div>
    <div class="container">
      <div class="content_nav">
        <router-link to="/about/culture">{{ text1 }}</router-link>
        <router-link to="/about/glory" class="active">{{ text2 }}</router-link>
      </div>
      <div class="container_content">
        <div class="title">
          <div class="title_nav show">
            <h3>2021</h3>
            <p>{{ text3 }}</p>
            <img class="toggle" @click="click()" src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/bottom.png" alt="" />
          </div>
          <div class="hide_box" v-show="isshow">
            <p>{{ text4 }}</p>
            <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/rongyu2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
import $ from "jquery";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "公司文化",
          text2: "公司荣誉",
          text3: "明济生物荣获2021年中国潜在独角兽企业奖",
          text4:
            "长城战略咨询总经理武文生发布了《2021中国潜在独角兽企业研究报告》。报告深入调研了2020-2021年间以异军突起之势兴起、开启了行业发展新赛道的独角兽“后备军”,这是长城战略咨询第二次发布中国潜在独角兽企业报告。此次发布会展示了中国潜在独角兽企业发展的风貌,以及它们在引领新赛道、促进区域经济高质量发展、推动新旧动能转换方面发挥的作用,揭示了新经济发展的未来方向。会上,长城战略咨询与潜在独角兽企业代表举行了颁奖仪式。",
          text5: "公司文化和荣誉",
          isshow: true,
        };
        break;
      case "en":
        return {
          text1: "Corporate Culture",
          text2: "Awards",
          text3: "FutureGen Won the 2021 China Potential Unicorn Enterprise Award. ",
          text4: `Wu Wensheng, the general manager of Great Wall Strategy Consulting, released the 2021 Research Report on Potential Unicorn Enterprises in China. This is the second time that Great Wall Strategy Consulting has released a report on potential unicorn enterprises in China, which takes an in-depth look at the "reserve army" of unicorns that will emerge as a new force in 2020-2021 and open a new track for the industry. The launch event showcased the development of the potential unicorn enterprises in China and their role in leading new tracks, promoting high-quality development of regional economies, and promoting the traditional drivers being replaced by new ones, and revealed the future direction of the new economy. At the launch event, Great Wall Strategy Consulting and the representatives of potential unicorn enterprises held an award ceremony. `,
          text5: "Corporate Culture and Honors",
          isshow: true,
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
  methods: {
    click() {
      this.isshow = !this.isshow;
      if (this.isshow == true) {
        // $(".title_nav").addClass("color").removeClass("color_gray");
        $(".toggle").css({
          transform: "rotate(180deg)",
        });
        $(".show").css({
          backgroundColor: "#203864",
          color: "#fff",
        });
      } else {
        $(".toggle").css("transform", "rotate(0deg)");
        // $(".title_nav").removeClass("color").addClass("color_gray");
        $(".show").css({
          backgroundColor: "rgba(204, 204, 204, 0.1)",
          color: "#333",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.color {
  background: #203864;
  color: #fff;
}
.color_gray {
  background: rgba(204, 204, 204, 0.4);
  color: #333;
}
.header {
  width: 100%;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/wenhua.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.container {
  width: 100%;
  .container_content {
    width: 100%;
    max-width: 1200px;
    margin: 150px auto;
    .title {
      margin-top: 20px;
      background-color: rgba(204, 204, 204, 0.4);
      .show {
        color: #fff;
        background: #203864;
      }
      .title_nav {
        height: 90px;
        position: relative;
        display: flex;
        align-items: center;

        h3 {
          width: 96px;
          height: 90px;
          line-height: 90px;
          text-align: center;
          font-size: 30px;
          color: #fff;
          background-color: #203864;
        }
        h3::after {
          position: absolute;
          top: 18%;
          left: 8%;
          content: "";
          width: 2px;
          height: 55px;
          background-color: rgba(204, 204, 204, 0.308);
        }
        p {
          font-size: 18px;
          margin-left: 32px;
        }
        img {
          position: absolute;
          left: 95%;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
        }
      }
      .hide_box {
        padding: 50px 123px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(204, 204, 204, 0.4);
        p {
          width: 488px;
          color: #203864;
          font-size: 16px;
          line-height: 26px;
        }
        img {
          width: 406px;
          height: 271px;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  .container {
    .container_nav {
      height: 60px;
      line-height: 60px;
      a {
        height: 60px;
        font-size: 18px;
        margin-right: 15px;
      }
      :nth-child(2) {
        padding-bottom: 17px;
      }
      .line_red::after {
        left: 0%;
        width: 75px;
      }
    }
    .container_content {
      width: 100vw;
      margin: 30px auto;
      padding: 20px;
      box-sizing: border-box;
      .title {
        .title_nav {
          h3 {
            width: 68px;
            font-size: 22px;
          }
          h3::after {
            left: 20%;
          }
          p {
            width: 59vw;
            font-size: 18px;
            margin-left: 12px;
          }
          img {
            left: 90%;
          }
        }
        .hide_box {
          position: relative;
          padding: 20px;
          flex-direction: column;
          box-sizing: border-box;
          p {
            width: 85vw;
            color: #203864;
            font-size: 16px;
            line-height: 1.8;
            margin-top: 180px;
            padding: 20px;
            box-sizing: border-box;
          }
          img {
            position: absolute;
            top: 3%;
            width: 335px;
            height: 180px;
          }
        }
      }
    }
  }
}
</style>
